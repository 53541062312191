import React, { useEffect, useState } from "react";
import "./styles/main.css";
import "./styles/global.scss";
// import { Routes, Route } from "react-router-dom";
// import About from "./about";
// import SolutionsLayout from "./routes/solutions/SolutionsLayout";
// import PaperClipLayout from "./routes/paperclip/PaperClip";
// import Expert from "./routes/solutions/expert/Expert";
// import Publications from "./routes/solutions/publications/Publications";
// import TalentBase from "./routes/solutions/talentBase/TalentBase";
// import PreConsultation from "./routes/solutions/preConsultation/PreConsultation";
// import Career from "./routes/solutions/career/Career";
// import CreatePublication from "./routes/solutions/publications/createPublication";
// import PaperClipExpert from "./routes/paperclip/expert/Expert";
// import Interview from "./routes/MadeIn/Interview/index";
// import Login from "./routes/Login";
// import SignUp from "./routes/SignUp";
// import Review from "./routes/MadeIn/Review";
// import SpurtX from "./routes/SpurtX";
// import Profile from "./routes/Clients/Profile";
// import Invoice from "./routes/Clients/Invoices";
// import Nomination from "./routes/MadeIn/Nomination";
// import ConsultantProfile from "./routes/Consultants/Profile";
// import Schedule from "./routes/Consultants/Schedule";
// import Dashboard from "./routes/Dashboard";
// import Admin from "./routes/Dashboard/admin";
// import DashboardInvoice from "./routes/Dashboard/invoice";
// import DashboardSchedule from "./routes/Dashboard/schedules";
// import "./styles/global.scss";
// import ClientSessions from "./routes/Clients/Sessions";
// import ClientServices from "./routes/Clients/Services";
// import Clients from "./routes/Dashboard/clientsList";
// import Consultants from "./routes/Dashboard/consultantsList";
// import AdminProfile from "./routes/Dashboard/adminProfile";

function App() {
	useEffect(()=>{
window.location.replace("https://www.spurtgroup.com")
	},[])

	return null;
	// return (
	// 	<Routes>
	// 		{/* <Route path="/" /> */}
	// 		<Route path="/" element={<Login />} />
	// 		<Route path="/madein/interview" element={<Interview />} />
	// 		<Route path="/madein/review" element={<Review />} />
	// 		<Route path="/madein/nominations" element={<Nomination />} />
	// 		<Route path="/admin/login" element={<Login />} />
	// 		<Route path="/admin/sign-up" element={<SignUp />} />
	// 		<Route path="/admin/profile" element={<AdminProfile />} />
	// 		<Route path="/about" element={<About />} />
	// 		<Route path="/spurtx/request" element={<SpurtX />} />
	// 		<Route path="/clients/profile" element={<Profile />} />
	// 		<Route path="/clients/invoices" element={<Invoice />} />
	// 		<Route path="/clients/sessions" element={<ClientSessions />} />
	// 		<Route path="/clients/services" element={<ClientServices />} />
	// 		<Route path="/consultants/consultant-profile" element={<ConsultantProfile />} />
	// 		<Route path="/consultants/schedules" element={<Schedule />} />

	// 		<Route path="/solutions" element={<SolutionsLayout />}>
	// 			<Route path="/solutions/expert" exact element={<Expert />} />
	// 			<Route path="/solutions/publications" exact element={<Publications />} />
	// 			<Route path="/solutions/publications/create-publication" exact element={<CreatePublication />} />
	// 			<Route path="/solutions/talent-base" exact element={<TalentBase />} />
	// 			{/* <Route path="/solutions/pre-consultation" exact element={<PreConsultation />} /> */}
	// 			<Route path="/solutions/career" exact element={<Career />} />
	// 		</Route>
	// 		<Route path="/solution" element={<SolutionsLayout />}>
	// 			<Route path="/solution/pre-consultation" exact element={<PreConsultation />} />
	// 		</Route>

	// 		<Route path="/paperclip" element={<PaperClipLayout />}>
	// 			<Route path="/paperclip/service" exact element={<PaperClipExpert />} />
	// 		</Route>
	// 		<Route path="/dashboard" element={<Dashboard />} />
	// 		<Route path="/admin" element={<Admin />} />
	// 		<Route path="/invoice" element={<DashboardInvoice />} />
	// 		<Route path="/schedules" element={<DashboardSchedule />} />
	// 		<Route path="/clients" element={<Clients />} />
	// 		<Route path="/consultants" element={<Consultants />} />
	// 	</Routes>
	// );
}

export default App;
